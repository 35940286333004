@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.notfound {
  background-color: #ffffff;
  text-align: center;
  padding: 200px 0;

  p {
    color: $color__gray;
  }
}
